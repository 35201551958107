import React, { useState } from 'react';

import '../../App.css';
import Loader from '../../components/Loader/Loader';
import Header from '../Header/Header';
import HeaderMenuLateral from '../MenuLateral/HeaderMenuLateral/HeaderMenuLateral';
import './Pagina.css';

function Pagina({ loading, children }) {
  const [menuState, setMenuState] = useState(false);
  function handleMenuChange() {
    setMenuState(!menuState);
  }
  return (
    <div className="paginaInteira">
      <HeaderMenuLateral
        menuState={menuState}
        handleMenuChange={handleMenuChange}
      />
      <div className="cadastroInteiro">
        <Header menuState={menuState} handleMenuChange={handleMenuChange} />
        <div className='contedoCadastro'>
          {!loading && { ...children }}
          {loading && <Loader />}
        </div>
      </div>
    </div>
  );
}

export default Pagina;
